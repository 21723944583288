import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import React, { useEffect } from "react";
import Footer from "./elements/Footer";
import Header from "./elements/Header";
import { Route, Link, Routes, useLocation } from "react-router-dom";
// import './style.scss';

const MainLayout = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <>
      <ToastContainer limit={1} />
      <Header />

      <Outlet />

      <Footer />
    </>
  );
};

export default MainLayout;
