
import {
  BSMImage,
  ETLImage,
  SocialMediaImg1,
  SocialMediaImg2,
} from "../../assets/img";
export const ServiceData = [
  {
    id: 1,
    name: "market-research",
    link: "market-research",
    bannerSec: {
      heading: "Customized Market Research",
    },
    content: [
      {
        id: 1,
        heading: "Research Design & Questionnaire Development",
        pid: "#research",
        des: (
          <>
            <p>
              ‘Defining’ and ‘Designing’ are the two most important aspects of
              any research. It is critical to understand the business problem,
              translate it into a research problem and then design the research
              around it, so that accurate information can be gathered to solve
              the business problem. StatWorld helps you put the right foot
              forward by helping you in following areas:
            </p>
          </>
        ),

        contentList: [
          {
            id: 1,
            list: (
              <>
                <span>Research Design</span> – Identifying key questions to be
                answered, what research should be conducted, most relevant
                research technique, critical assumptions and limitations of
                research, optimal sample size for statistically viable results,
                analysis plan etc.
              </>
            ),
          },
          {
            id: 2,
            list: (
              <>
                <span>Questionnaire Development</span> – Drafting a
                questionnaire around the research topic with most appropriate
                questions. We have experience of developing questionnaires
                across multiple sectors, for a wide range of studies such as
                brand assessment, attitude and usage, concept testing, message
                testing, customer satisfaction, employee surveys, brand
                tracking, pricing, advertising effectiveness etc.
              </>
            ),
          },
        ],
      },
      {
        id: 2,
        heading: "Survey Programming & Hosting",
        pid: "#survey",
        des: (
          <>
            <p>
              We offer end-to-end survey management services from dedicated
              teams with extensive experience. Our services include:
            </p>
          </>
        ),
        contentList: [
          {
            list: "Programming of Customized surveys with high-end interactive functionalities",
          },
          {
            list: "Customized look-and-feel of survey as per the brand elements of client",
          },
          {
            list: "Programming of multi-lingual surveys in any foreign language",
          },
          {
            list: "Programming of Online surveys (works with internet), CAPI/Offline surveys (works without internet), and CATI surveys (for telephonic interviews)",
          },
          {
            list: "Programming of Conjoint surveys of all types (ACA, CBC, ACBC, MBC, Max-Diff)",
          },
          {
            list: "Survey hosting on our own dedicated and secure servers",
          },
          {
            list: "Very fast turn-around time with 24/7 dedicated teams",
          },
        ],
      },
      {
        id: 3,
        heading: "Data Collection & Field Management",
        pid: "#data",
        marginTop: true,
        des: (
          <>
            <p>
              We offer following data collection and field management services:
            </p>
          </>
        ),
        contentList: [
          {
            list: "Sample procurement using our own panel and panel network.",
            listOfList: [
              "Consumer sample",
              "Business sample",
              "Physician sample (for healthcare studies)",
            ],
          },
          {
            list: "Sample procurement for hard-to-reach target audience in different countries around the globe",
          },
          {
            list: "Managing survey launch process through external panels, customer list (mass-mailing), offline (telephonic/intercept)",
          },
          {
            list: "In-field management such as panel coordination, quota monitoring, technical support to respondents",
          },
          {
            list: "Live reporting of in-field progress to the clients",
          },
          {
            list: "Data availability in multiple formats such as SPSS, Excel, CSV, SAS, ASCII etc.",
          },
          {
            list: "Online live reporting of survey data/results through web-dashboards",
          },
        ],
      },
      {
        id: 4,
        heading: "Analysis & Reporting",
        pid: "#analysis",
        marginBottom: false,
        des: <p>We offer following analysis & reporting services:</p>,
        contentList: [
          {
            list: "In-depth data analysis by different segments on primary research data",
          },
          {
            list: "In-depth data analysis on secondary data available from different sources",
          },
          {
            list: "Data integration of primary and secondary data sets from various sources",
          },
          {
            list: (
              <>
                Interactive visualization and reporting of results using
                professional BI & Data visualization tools such as Tableau
              </>
            ),
          },
          {
            list: "Reporting of results with analytical insights in PowerPoint Reports",
          },
          {
            list: "Dashboard Reporting – Online dashboards, Excel dashboards",
          },
          {
            list: "Data tables with significance testing, weighting etc. in Excel/MS Word",
          },
        ],
      },
    ],
  },

  {
    id: 2,
    name: "Advanced Analytics",
    link: "advanced-analytics",
    bannerSec: {
      heading: "Advanced Analytics",
    },
    content: [
      {
        id: 1,
        heading: "Discrete Choice Modeling (Conjoint Analysis)",
        pid: "#discrete",
        des: (
          <p>
            StatWorld has got a distinctive expertise in Discrete Choice
            Modeling (DCM), more commonly known as Conjoint Research. Having
            applied DCM in over 200 business problems, spanning across diverse
            sectors, our team is your go-to experts for this type of research.
            We have subject matter experts and team with global expertise on
            advanced analytical techniques.
          </p>
        ),
        // endLinkSec: (
        //   <p>
        //     Click here to view more about{" "}
        //     <Link href="/">Discrete Choice Modeling/Conjoint Research</Link>
        //   </p>
        // ),
        contentList: [
          {
            id: 1,
            list: (
              <>
                End-to-end execution including - Experimental Design, Survey
                Programming, Utilities Estimation, &amp; Interactive Market
                Simulator
              </>
            ),
          },
          {
            id: 2,
            list: (
              <>
                Experience working on 200+ Conjoint studies using CBC, ACBC,
                MBC, Max-Diff
              </>
            ),
          },
          {
            id: 3,
            list: (
              <>
                Highly interactive and functional Market Simulators with
                features such as:
              </>
            ),
            listOfList: [
              "Demand Curves",
              "Sensitivity Analysis",
              "Scenario Comparison",
              "Monetary Dollar Value Attribution",
            ],
          },
          {
            id: 4,
            list: (
              <>
                Executed Conjoint studies to identify pricing strategies,
                product development strategies, market growth strategies, market
                segmentation etc.
              </>
            ),
          },
          {
            id: 5,
            list: (
              <>
                Applied Conjoint in diverse industries such as Pharmaceutical,
                CPG, Real Estate, Hospitality, F&amp;B, Automotive, Medical
                Devices, IT &amp; Telecom, Banking &amp; Finance, and many more…
              </>
            ),
          },
        ],
      },
      {
        id: 2,
        heading: "Market Segmentation",
        pid: "#market",
        des: (
          <>
            <p>
              Market segmentation is one of the most important basis of many
              successful marketing strategies. The identified segments allow
              tailoring the marketing mix to more individual customer needs,
              thus helps to invest marketing spending more effectively.
            </p>

            <p>
              StatWorld has got a distinctive expertise when it comes to
              segmentation. Our team has extensive global experience in applying
              market segmentation, to develop marketing strategies aiming
              towards more efficient advertising, accurate branding, sales force
              effectiveness, customer retention and much more.
            </p>
          </>
        ),
        contentList: [
          {
            list: (
              <>
                End-to-end execution including - Questionnaire Development,
                Survey Programming, Segmentation, Profiling, Personas
                Development, &amp; DFA Modeling for Segment Extrapolation to
                Market
              </>
            ),
          },
          {
            list: (
              <>
                Experience working on 100+ Segmentation studies involving
                different techniques such as:
              </>
            ),
            listOfList: [
              "K-means",
              "2-Step",
              "Latent Class",
              "CART / CHAID",
              "Hierarchical",
              "Discriminant Analysis",
            ],
          },
          {
            list: (
              <>
                Detailed reporting at Segment level through PPT reports,
                dashboards
              </>
            ),
          },
          {
            list: (
              <>
                In-depth Profiling of segments to develop distinctly clear
                “Personas” with a actionable roadmap
              </>
            ),
          },
          {
            list: (
              <>
                Executed segmentation of various types such as psychographic,
                attitudinal and behavioral, need-based, demographic/geographic,
                conjoint based
              </>
            ),
          },
        ],
      },
      {
        id: 3,
        heading: "Brand Strength Monitor (BSM)",
        pid: "#bsm",
        des: (
          <p>
            A comprehensive brand strength evaluation and monitoring instrument,
            for systematic assessment of brand’s health and effectiveness in the
            market vs. competition. With impressive results in improving brand
            performance and marketing ROI, this instrument is highly effective
            in providing very specific inputs that help in bringing a strategic
            clarity in developing a clear roadmap for brand performance
            improvement and brand growth.
          </p>
        ),
        imgSec: {
          src: BSMImage,
          alt: "bsm",
        },
      },
      {
        id: 4,
        heading: "Pricing Research",
        pid: "#pricing",
        marginTop: true,
        contentList: [
          {
            list: "Price Optimization & Price Elasticity",
          },
          {
            list: "Price Sensitivity Analysis",
          },
          {
            list: "Bundling Strategies",
          },
          {
            list: "Monetary Value Attribution Modelling",
          },
        ],
      },
      {
        id: 5,
        heading: "Multivariate Analytics & Predictive Modelling",
        pid: "#marketing",
        contentList: [
          {
            list: "Factor Analysis",
          },
          {
            list: "Key Driver Analysis",
          },
          {
            list: "Discriminant Analysis",
          },
          {
            list: "Regression Analysis",
          },
          {
            list: "Structural Equation Modeling (SEM)",
          },
          {
            list: "Logistic Regression",
          },
          {
            list: "Predictive modelling",
          },
        ],
      },
      {
        id: 6,
        heading: "CRM Analytics",
        pid: "#crm",
        contentList: [
          {
            list: "Descriptive Analytics: Churn Analysis & Customer Segmentation",
          },
          {
            list: "Predictive Analytics: CLV Modelling, Cross-selling/Up-selling, Lead Scoring",
          },
          {
            list: "Prescriptive Analytics, Behavioral Analytics, Spend Analysis etc",
          },
        ],
      },
      {
        id: 7,
        heading: "Marketing & Sales Effectiveness Analytics",
        pid: "#marketassessment",
        contentList: [
          {
            list: "Market Mix Modelling",
          },
          {
            list: "Sales & Purchase Funnel Analysis",
          },
          {
            list: "ROI Analysis",
          },
          {
            list: "Market Simulation and What-If Analysis",
          },
        ],
      },
      {
        id: 8,
        heading: "Market Assessment & Forecasting Models",
        pid: "#marketassessment",
        contentList: [
          {
            list: "Market Assessment and Sizing",
          },
          {
            list: "Market Opportunity Evaluation",
          },
          {
            list: "Forecasting Models",
          },
        ],
      },
    ],
  },

  {
    id: 3,
    name: "Social Media Analytics",
    link: "social-media-analytics",
    bannerSec: {
      heading: "Social Media Analytics",
    },
    desc: "Conversations matter, more so in social media marketing. Social media listening helps us understand actual conversations, of real customers, and what they desire. Social media analysis if done effectively, can increase revenue and sales and can have a lasting impact on the brand. We uncover the why’s and how’s of social media listening. By using advanced tools and techniques, machine learning, text analytics and integrating it with traditional research, we offer great strategic insights in a comprehensive manner.",
    imgSec: {
      src: SocialMediaImg1,
      alt: "Social Media",
    },
    imgSec1: {
      src: SocialMediaImg2,
      alt: "Social Media",
    },
  },

  {
    id: 4,
    name: "Bi Data Visualization",
    link: "bi-data-visualization",
    bannerSec: {
      heading: "BI and Data Visualization",
    },
    desc: (
      <>
        Our Data Visualization experts help putting research into perspective
        with highly interactive dashboards, programmed in Tableau and Power BI.
        Our supplemental research services aim to bolster the main research to
        add further value.
      </>
    ),
    content: [
      {
        id: 1,
        heading: "Tableau & Power BI Dashboards",
        pid: "#interactive",
        des: (
          <>
            <p>
              Programming dynamic and interactive Online Dashboards with
              following features:
            </p>
          </>
        ),
        contentList: [
          {
            list: "Present complex data in visually appealing interactive charts, graphs etc.",
          },
          {
            list: "Drill-down capability, ability to see results by various cuts using Filters",
          },
          {
            list: "Integrate data from multiple sources, for multiple markets",
          },
          {
            list: "Real-time or near real-time monitoring of performance",
          },
          {
            list: "Security and access controls, with sharing through web-links",
          },
          {
            list: "Dashboards for Tracker studies, with ability to compare results across waves on same screen",
          },
        ],
      },
      {
        id: 2,
        heading: "Periodic Tracking Reports/ Dashboards",
        pid: "#periodic",
        des: (
          <p>
            Reporting of results on a periodic basis to track/improve
            performance, with an ability to implement ETL functionalities,
            integrating data from multiple sources and using that for reporting.
          </p>
        ),
      },
      {
        id: 3,
        heading: "Competitive Benchmarking",
        pid: "#CompetitiveBenchmarking",
        des: (
          <p>
            Doing competitive benchmarking to establish performance of brand
            vis-à-vis competition, using quantitative measures as well as
            qualitative measures.
          </p>
        ),
      },
      {
        id: 4,
        heading: "Process Automation",
        pid: "#ProcessAutomation",
        des: (
          <p>
            Process automation leads to operational excellence and high quality
            outputs, leading to cost savings and resource optimization. We
            automate many tedious and time consuming tasks, using VBA, macros
            and other programming languages, so that everything happens with the
            click of a button.
          </p>
        ),
      },
    ],
  },
  {
    id: 5,
    name: "ETL Implementation",
    link: "etl-implementation",
    bannerSec: {
      heading: "ETL Implementation",
    },
    imgSec: {
      src: ETLImage,
      alt: "etl",
    },

    // content: [
    //   {
    //     id: 1,
    //     heading: "ETL Services",
    //     pid: "#interactive",
    //     des: (
    //       <>
    //         <p>
    //           Dashboards that present the data in the most user friendly and
    //           comprehensive manner
    //         </p>
    //       </>
    //     ),
    //     contentList: [
    //       {
    //         list: "End-to-end ETL Implementation & Management",
    //       },
    //       {
    //         list: "Comprehensive Reporting using Interactive Dashboards",
    //       },
    //       {
    //         list: "Integrate ETL with BI Mechanisms for Deeper Insights",
    //       },
    //       {
    //         list: "Ability to go toNext Level with Advance Analytics",
    //       },
    //     ],
    //   },
    // ],
  },
];

export const ServiceList = [
  {
    id: 1,
    name: "market-research",
    title: "Customized Market Research",
    innerList: [
      {
        name: "Research Design & Questionnaire Development",
      },
      {
        name: "Survey Programming & Hosting",
      },
      {
        name: "Data Collection & Field Management",
      },
      {
        name: "Analysis & Reporting",
      },
    ],
  },
  {
    id: 2,
    name: "advanced-analytics",
    title: "Advanced Analytics",
    innerList: [
      {
        name: "Discrete Choice Modeling",
      },
      {
        name: "Market Segmentation",
      },
      {
        name: "Brand Strength Monitor (BSM)",
      },
      {
        name: "Pricing Research",
      },
      {
        name: "Multivariate Analytics & Predictive Modelling",
      },
      {
        name: "CRM Analytics",
      },
      {
        name: "Marketing & Sales Effectiveness Analytics",
      },
      {
        name: "Market Assessment & Forecasting Models",
      },
    ],
  },
  {
    id: 3,
    name: "social-media-analytics",
    title: "Social Media Analytics",
  },
  {
    id: 4,
    name: "bi-data-visualization",
    title: "BI & Data Visualization",
    innerList: [
      {
        name: "Tableau & Power BI Dashboards",
      },
      {
        name: "Periodic Tracking Reports/ Dashboards",
      },
      {
        name: "Competitive Benchmarking",
      },
      {
        name: "Process Automation",
      },
    ],
  },
  {
    id: 5,
    name: "etl-implementation",
    title: "ETL Implementation",
  },
];
