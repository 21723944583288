import BannerInner from "../../components/Banner/BannerInner";
import HomePageForm from "../../components/Home/HomePageForm";
import ManagementTeamDesign1 from "../../components/Testimonials/TestimonialItem";
function Testimonials() {
  const aboutBannerData = {
    heading: "Testimonials"
  }
  return (
    <>
      <BannerInner
        bannerData={aboutBannerData}
      />
      <ManagementTeamDesign1 />
      <HomePageForm />
    </>
  );
}

export default Testimonials;
