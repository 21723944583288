import React from 'react'
import { Link } from 'react-router-dom'
import { MarketResearchIcon, MarketResearchIcon2, advanceAnalyticsIcon, dataVisualizationIcon, etlIcon, marketResearchIcon1, socialMediaAnalyticsIcon } from '../../assets/img'
import styles from './../../assets/css/sevice-hmg.module.scss'
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";


const HmgBanner = () => {
    return (
        <>
            <section className={`pdtb ${styles.hmg_service_sec}`} id='hmgService'>
                <div className="container-fluid">
                    <div className="row justify-content-center">
                        <div className="col-xxl-8 col-xl-10 text-center">
                            <div className="sub-heading mb-0">
                                <h2 className="text-white text-uppercase">The Best <span>Solution</span> For Your Business</h2>
                                <p className="text-white mb-0">Explore StatWorld's suite of services: from bespoke market research design to insightful data analytics. We guide you at every step, ensuring informed decisions backed by robust data.</p>
                            </div>
                        </div>
                    </div>
                    <ul>
                        <li className="">
                            <Link to="/market-research" className={styles.hmg_service_item}>
                                <div className={styles.hmg_service_item_img}>
                                    <img src={marketResearchIcon1} alt="Market Research" />
                                </div>
                                <div className={styles.hmg_service_item_img_bg}>
                                    <img src={marketResearchIcon1} alt="Market Research" />
                                </div>
                                <h4>Customized Market Research</h4>
                                <p>
                                 Delivering Precision-Crafted Insights to Empower Confident Decision-Making.
                                </p>
                                <span className={styles.lear_more_text}>
                                    Learn More
                                    {/* <i className={styles.arrow_left}><BiChevronLeft /></i> */}
                                    <i className={styles.arrow_right}><BiChevronRight /></i>
                                </span>
                            </Link>
                        </li>
                        <li className="">
                            <Link to="/advanced-analytics" className={styles.hmg_service_item}>
                                <div className={styles.hmg_service_item_img}>
                                    <img src={advanceAnalyticsIcon} alt="Advanced Analytics" />
                                </div>
                                <div className={styles.hmg_service_item_img_bg}>
                                    <img src={advanceAnalyticsIcon} alt="Advanced Analytics" />
                                </div>
                                <h4>Advanced Analytics</h4>
                                <p>Equipping You with Essential Intelligence to Excel in a Competitive Landscape.</p>
                                <span className={styles.lear_more_text}>
                                    Learn More
                                    {/* <i className={styles.arrow_left}><BiChevronLeft /></i> */}
                                    <i className={styles.arrow_right}><BiChevronRight /></i>
                                </span>
                            </Link>
                        </li>
                        <li className="">
                            <Link to="/other-research" className={styles.hmg_service_item}>
                                <div className={styles.hmg_service_item_img}>
                                    <img src={socialMediaAnalyticsIcon} alt="Social Media Analytics" />
                                </div>
                                <div className={styles.hmg_service_item_img_bg}>
                                    <img src={socialMediaAnalyticsIcon} alt="Social Media Analytics" />
                                </div>
                                <h4>Social Media Analytics</h4>
                                <p>Delivering Profound Insights into Audience Sentiment, Emerging Trends, and Invaluable Opportunities.</p>
                                <span className={styles.lear_more_text}>
                                    Learn More
                                    {/* <i className={styles.arrow_left}><BiChevronLeft /></i> */}
                                    <i className={styles.arrow_right}><BiChevronRight /></i>
                                </span>
                            </Link>
                        </li>
                        <li className="">
                            <Link to="/etl-implementation" className={styles.hmg_service_item}>
                                <div className={styles.hmg_service_item_img}>
                                    <img src={etlIcon} alt="ETL Implementation" />
                                </div>
                                <div className={styles.hmg_service_item_img_bg}>
                                    <img src={etlIcon} alt="ETL Implementation" />
                                </div>
                                <h4>ETL Implementation</h4>
                                <p>Unleash Data&#39;s Potential by Ensuring Smooth and Precise Data Flow, Enhancing Efficiency and Data Integrity.</p>
                                <span className={styles.lear_more_text}>
                                    Learn More
                                    {/* <i className={styles.arrow_left}><BiChevronLeft /></i> */}
                                    <i className={styles.arrow_right}><BiChevronRight /></i>
                                </span>
                            </Link>
                        </li>
                        <li className="">
                            <Link to="/bi-data-visualization" className={styles.hmg_service_item}>
                                <div className={styles.hmg_service_item_img}>
                                    <img src={dataVisualizationIcon} alt="Data Visualization" />
                                </div>
                                <div className={styles.hmg_service_item_img_bg}>
                                    <img src={dataVisualizationIcon} alt="Data Visualization" />
                                </div>
                                <h4>Data Visualization</h4>
                                <p>Transforming Insights into Clear, Actionable Information for Confident, Data-Driven Decisions.</p>
                                <span className={styles.lear_more_text}>
                                    Learn More
                                    {/* <i className={styles.arrow_left}><BiChevronLeft /></i> */}
                                    <i className={styles.arrow_right}><BiChevronRight /></i>
                                </span>
                            </Link>
                        </li>
                    </ul>
                </div>
            </section>
        </>
    )
}


export default HmgBanner;