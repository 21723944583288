import { useRoutes } from 'react-router-dom';
// routes
import MainRoutes from './MainRoutes';
import  Cookies from 'js-cookie';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
   

      var routesArr = [MainRoutes];

      return useRoutes([...routesArr]);

    //  var routes = (Cookies.get('jwtToken') !== "" && Cookies.get('jwtToken') !== undefined)?[MainRoutes,PagesRoutes]: [AuthRoutes]; 
    //  return useRoutes([..routes])

   // return useRoutes([...routesArr]);

   

}
