import React from 'react'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// import { Link, useNavigate } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import { Bghmgimg } from '../../assets/img'
import styles from './../../assets/css/hmg-banner.module.scss'
const HmgBanner = () => {
    var settings = {
        dots: true,
        infinite: true,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        cssEase: 'ease-in-out',
        centerPadding: "10px",
        autoplay: true,
        speed: 3000,
        autoplaySpeed: 5000,
        // useTransform: true,
        // cssEase: 'cubic-bezier(0.645, 0.045, 0.355, 1.000)',
        adaptiveHeight: true,
    };

    function scrollToServiceOffering() {
        var element = document.getElementById('hmgService');
        var headerOffset = 100;
        var elementPosition = element.getBoundingClientRect().top;
        var offsetPosition = elementPosition + window.pageYOffset - headerOffset;
        window.scrollTo({
            top: offsetPosition,
            behavior: "smooth"
        });
    }
    const navigate = useNavigate();

    const handleMove = (id) => {
        navigate("/about-company");
        setTimeout(() => {
            const element = document.getElementById(id);
            if (element) {
                const headerOffset = 200;
                const elementPosition = element.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth",
                });
            }
        }, 100);
    };
    return (
        <>
            <section className={`${styles.hmg_banner} hmg-banner-sl`}>
                <img className={styles.hmg_bg_img} src={Bghmgimg} alt="banner"/>
                <div className="h-100">
                    <div className='container h-100'>
                        <div className="row align-items-center h-100">
                            <div className='col-lg-12'>
                                <Slider
                                    {...settings}
                                >
                                    <div>
                                        <div className={styles.hmg_banner_contnt}>
                                            <h3>Create Strategy as per your Unique Business Needs with our</h3>
                                            <h1>Customized Analytics and Market Research Solutions!</h1>
                                            <p>
                                                Our bespoke market research and analytics solutions are uniquely crafted to meet your specific business needs.
                                            </p>
                                                <div className="d-flex justify-content-center text-center">
                                                    <button className={styles.read_more_btn} onClick={() => handleMove("customizedAnalytics")}>Read More...</button>
                                                    {/* <AboutCompanyModel {...MarketResearchData}/> */}
                                                </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.hmg_banner_contnt}>
                                            <h3>Unlock the Power of Data with Our</h3>
                                            <h1>INTEGRATED ANALYTICS ECOSYSTEM!</h1>
                                            <p>
                                                STATWORLD is your ONE-STOP destination for Customized Market Research, Advanced Analytics,
                                                Social Media Analytics, ETL Implementation, Data Visualization, and related services.
                                            </p>
                                                <div className="d-flex justify-content-center text-center">
                                                    <button className={styles.read_more_btn} onClick={() => handleMove("ecosystem")}>Read More...</button>
                                                    {/* <AboutCompanyModel {...ecosystemData} /> */}
                                                </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className={styles.hmg_banner_contnt}>
                                            <h3>Empower Your Brand with</h3>
                                            <h1>StatWorld’s Brand Strength Monitor (BSM)!</h1>
                                            <p>
                                                StatWorld&#39;s Brand Strength Monitor (BSM) is a transformative and comprehensive brand strength evaluation instrument.
                                                </p>
                                                {/* <Link to="/about-company" >Read More...</Link> */}
                                                <div className="d-flex justify-content-center text-center">
                                                    <button className={styles.read_more_btn} onClick={() => handleMove("brandStrength")}>Read More...</button>
                                                    {/* <AboutCompanyModel {...BSMData} /> */}
                                                </div>
                                        </div>
                                    </div>
                                </Slider>
                                <div className={`text-center ${styles.btm_btn_sec}`}>
                                    <button onClick={scrollToServiceOffering} className={styles.hmg_banner_btn}>Check out our service offerings</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
export default HmgBanner;