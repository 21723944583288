// import { Link } from "react-router-dom";
import BannerInner from "../../components/Banner/BannerInner";
import HomePageForm from "../../components/Home/HomePageForm";
import { ContactPageDataMeta } from "../../components/jsonData/HomePageDataMeta";
import HeadMetaSec from "../../components/HeadMetaSec";
function ContactUs() {
  const contactBannerData = {
    heading: "Contact Us"
  }
  return (
    <>
      <HeadMetaSec HeadSecData={ContactPageDataMeta.helmetSec} />
      <BannerInner
        bannerData={contactBannerData}
      />
      <HomePageForm />
    </>
  );
}

export default ContactUs;
