import { aboutStatWorldBSM, aboutStatWorldEcosystem, marketResearch } from "../../assets/img";

export const AboutStatWorldData = [
  {
    id: 1,
    pid:"customizedAnalytics",
    alt:"analyticsMarketResearch",
    imgSrc: marketResearch,
    heading: "Customized Analytics and Market Research Solutions!",
    desc: (
      "Our bespoke market research and analytics solutions are uniquely crafted to meet your specific business needs, empowering you with the strategic intelligence necessary to outperform the competition. At StatWorld, we are dedicated to provide our global clientele with a competitive edge through our personalized analytics and comprehensive market research offerings. Our expertise lies in harnessing advanced statistical methods, AI capabilities, and state-of-the-art technology to shape precise insights that perfectly match your business's distinctive requirements. We offer solutions that empower you to navigate complexities confidently, ensuring that every strategic decision you make is backed by data-driven analysis and insights, that fuel your global success."
    ),
    linkSec: {
      name: "Check out our service offerings",
      href: "/market-research",
    },
  },
  {
    id: 2,
    imgSrc:aboutStatWorldEcosystem,
    heading: "INTEGRATED ANALYTICS ECOSYSTEM!",
    pid:"ecosystem",
    alt:"analyticsEcosystem",
    orderImg:"order-lg-2 order-md-1",
    orderContent:"order-lg-1 order-md-2",
    desc: (
      <>
        STATWORLD is your ONE-STOP destination for Customized Market Research,
        Advanced Analytics, Social Media Analytics, ETL Implementation, Data
        Visualization, and related services. Our integrated analytics ecosystem
        empowers us to fulfil all client requirements in-house, ensuring a
        seamless and comprehensive service experience.
      </>
    ),
    list: [
      "Customized Market Research",
      "Advanced Analytics Solutions",
      "Social Media Analytics",
      "Effortless ETL Implementation",
      "Data Visualization & Dynamic Dashboards",
    ],
    linkSec: {
      name: "Check out our service offerings",
      href: "/advanced-analytics",
    },
  },
  {
    id: 3,
    imgSrc: aboutStatWorldBSM,
    heading: "StatWorld’s Brand Strength Monitor (BSM)!",
    pid:"brandStrength",
    alt:"brandMonitor",
    desc: (
      <>
        StatWorld's Brand Strength Monitor (BSM) is a transformative and
        comprehensive brand strength evaluation instrument, meticulously
        designed for the systematic assessment of your brand's health and
        effectiveness in the market vis-à-vis competition. Operating across six
        key dimensions, the BSM has the power to catapult your brand to new
        heights and magnify its impact. Throughout our years of experience, we
        have harnessed this research instrument with remarkable success,
        bolstering the performance and marketing ROI of numerous multinational
        giants. The BSM delivers precise, actionable insights, illuminating the
        path to strategic clarity and the development of a well-defined roadmap
        for enhancing brand performance and driving sustainable brand growth
      </>
    ),
    linkSec: {
      name: "Check out our service offerings",
      href: "/market-research",
    },
  },
];
