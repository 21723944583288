import styles from "../../assets/css/testimonials-item.module.scss";
import { Quote1, Quote2 } from "../../assets/img";
import { testimonialData } from "./TestimonialData";

const TestimonialItem = () => {
  return (
    <>
      <section className={`pdtb ${styles.testimonial_page_sec}`}>
        <div className="container">
          <div className="sub-heading mb-0">
            <h2 className="text-center">Testimonials</h2>
          </div>
          <div className={`${styles.testimonial_item}`}>
            {testimonialData.map((item, i) =>
              <div className={`${styles.testimonial_item_list}`} id={item.pid} key={i}>
                <h4>{item.companyName}</h4>
                <h5>{item.designation}</h5>
                <div className={styles.desc_sec}>
                {item.desc}
                </div>
                {
                  item.list &&
                  <ul>
                    {item.list.map((list, i)=>
                    <li key={i}>{list}</li>
                    )}
                  </ul>
                }
                <img
                  className={styles.quote_imgtp}
                  src={Quote1}
                  alt="Quote"
                />
                <img
                  className={styles.quote_imgbtm}
                  src={Quote2}
                  alt="Quote"
                />
              </div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default TestimonialItem;
