import React from "react";
// import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import MainLayout from "../layouts/MainLayout";
import Home from "../views/pages/Home";
import ServicePage from "../views/pages/ServicePage";
import AboutUs from "../views/pages/AboutUs";
import ContactUs from "../views/pages/ContactUs";
import AboutCompany from "../views/pages/AboutCompany";
import Testimonials from "../views/pages/Testimonials";

const MainRoutes = {
  path: "",
  element: <MainLayout />,
  children: [
    {
      path: "",
      element: <Home />,
    },
    {
      path: "*",
      element: <Home />,
    },
    {
      path: "/:name",
      element: <ServicePage />,
    },
    {
      path: "contact-us",
      element: <ContactUs />,
    },
    {
      path: "about-us",
      element: <AboutUs />,
    },
    {
      path: "about-company",
      element: <AboutCompany />,
    },
    {
      path: "testimonials",
      element: <Testimonials />,
    },
  ],
};

export default MainRoutes;
