import React from "react";
import "slick-carousel/slick/slick.css";
import Slider from "react-slick";
import { Quote1, Quote2 } from "../../assets/img";
import styles from "./../../assets/css/client-hmg-slider.module.scss";
import { Link } from "react-router-dom";

const ClientsSaySlider = () => {
  const slider = React.useRef(null);

  const settings = {
    centerMode: true,
    centerPadding: "70px",
    slidesToShow: 1,
    adaptiveHeight: false,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 2000,

    responsive: [
      {
        breakpoint: 1399,
        settings: {
          centerPadding: "30px",
        },
      },
      {
        breakpoint: 1008,
        settings: {
          centerPadding: "20px",
        },
      },
      {
        breakpoint: 575,
        settings: {
          centerPadding: "0px",
        },
      },
    ],
  };

  const handleScroll = (id) => {
    setTimeout(() => {
        const element = document.getElementById(id);
        console.log(element);
        if (element) {
            const headerOffset = 200;
            const elementPosition = element.getBoundingClientRect().top;
            const offsetPosition = elementPosition - headerOffset;
            window.scrollTo({
                top: offsetPosition,
                behavior: "smooth",
            });
        }
    }, 100);
};
  return (
    <>
      <section className={`${styles.clint_slider} pdtb wht-our-slider`}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-8">
              <div className="sub-heading text-center">
                <h2 className="text-uppercase">
                  What our <span>clients</span> say
                </h2>
                <p>
                  Hear directly from those who've experienced the StatWorld
                  difference. Our clients' feedback shines a light on the value
                  and expertise we bring to every project.
                </p>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="clint-slid">
                <Slider ref={slider} {...settings}>
                  <div>
                    <div>
                      <div className={`${styles.slide_say_clint}`}>
                        <div className={styles.slide_img_contant}>
                          <h6>Brand Strategy Consulting Firm in the US</h6>
                          <span>President of a Brand Strategy</span>
                          <p>
                            We have worked with StatWorld for almost 10 years.
                            During that time they have become an integral part
                            of our team. StatWorld supports us with survey
                            design, programming, sample sourcing, analysis and
                            presentation services. Their work is always
                            exceptional...on time and on budget. They go beyond
                            just delivery to providing essential insights that
                            drive business results. Their work is invaluable and
                            I would highly recommend them to any company looking
                            to gain an intelligence advantage.
                          </p>
                          <div className="text-center">
                            <Link to="/testimonials" onClick={()=>handleScroll("#client1")}>Read More...</Link>
                          </div>
                          <img
                            className={styles.quote_imgtp}
                            src={Quote1}
                            alt="Quote"
                          />
                          <img
                            className={styles.quote_imgbtm}
                            src={Quote2}
                            alt="Quote"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className={`${styles.slide_say_clint}`}>
                        <div className={styles.slide_img_contant}>
                          <h6>Pricing Strategy Firm in the US</h6>
                          <span>CEO of a Pricing Strategy Firm</span>
                          <p>
                            We have now successfully worked together on a number
                            of projects covering multiple industries, and I am
                            very pleased with our collaboration. Below are the
                            main factors I believe have made our collaborations
                            successful.
                          </p>
                          <div className="text-center">
                            <Link to="/testimonials" onClick={()=>handleScroll("#client2")}>Read More...</Link>
                          </div>
                          <img
                            className={styles.quote_imgtp}
                            src={Quote1}
                            alt="Quote"
                          />
                          <img
                            className={styles.quote_imgbtm}
                            src={Quote2}
                            alt="Quote"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className={`${styles.slide_say_clint}`}>
                        <div className={styles.slide_img_contant}>
                          <h6>Strategy Consulting Firm in the EU</h6>
                          <span>CEO of a Strategy Consulting</span>
                          <p>
                            I have been working with StatWorld for almost 10
                            years now and have always enjoyed working with them.
                            Their subject matter expertise in Market Research
                            and Advanced Analytics, especially in Discrete
                            Choice Modeling is top rated. The guidance they
                            provide in research conceptualization phase is
                            praise worthy and have always been very helpful to
                            me in deciding the most suitable research for my
                            clients. They have a sound knowledge and strong
                            technical expertise in using Sawtooth software for
                            general surveys as well as conjoint surveys.
                          </p>
                          <div className="text-center">
                            <Link to="/testimonials" onClick={()=>handleScroll("#client3")}>Read More...</Link>
                          </div>
                        </div>
                        <img
                          className={styles.quote_imgtp}
                          src={Quote1}
                          alt="Quote"
                        />
                        <img
                          className={styles.quote_imgbtm}
                          src={Quote2}
                          alt="Quote"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className={`${styles.slide_say_clint}`}>
                        <div className={styles.slide_img_contant}>
                          <h6>Management Consulting Firm in the UK</h6>
                          <span>Analytics Director</span>
                          <p>
                            I have worked and partnered with StatWorld on
                            several projects over the last 8 years. StatWorld’s
                            proficiency in survey programming, project management
                            and quality of deliverables whether it is a standard
                            or complex project is excellent. They are one of the
                            best partners&#39; I have worked with. Besides their
                            customer service extends past the life of the
                            project. Rajat and Rachin are extremely responsive
                            and customize the solution to fit the needs of each
                            and every client. I would be happy to recommend
                            StatWorld at any point of time.
                          </p>
                          <div className="text-center">
                            <Link to="/testimonials" onClick={()=>handleScroll("#client4")}>Read More...</Link>
                          </div>
                        </div>
                        <img
                          className={styles.quote_imgtp}
                          src={Quote1}
                          alt="Freeman Thomas"
                        />
                        <img
                          className={styles.quote_imgbtm}
                          src={Quote2}
                          alt="Freeman Thomas"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <div>
                      <div className={`${styles.slide_say_clint}`}>
                        <div className={styles.slide_img_contant}>
                          <h6>Brand Management Firm in the US</h6>
                          <span>Director of Analytics</span>
                          <p>
                            I am really impressed with the outstanding analytics
                            services provided by StatWorld. From the moment we
                            engaged with their team, their dedication to our
                            project was excellent. Getting a profound
                            understanding of our business objectives quickly,
                            they suggested many things which helped us implement
                            the research much more impactfully. Throughout the
                            engagement, their attention to detail was
                            exceptional, and their ability to distil complex
                            data into actionable recommendations was
                            instrumental in driving our strategic decisions,
                            which really helped us see improvements in many
                            areas.
                          </p>
                          <div className="text-center">
                            <Link to="/testimonials" onClick={()=>handleScroll("#client5")}>Read More...</Link>
                          </div>
                        </div>
                        <img
                          className={styles.quote_imgtp}
                          src={Quote1}
                          alt="Freeman Thomas"
                        />
                        <img
                          className={styles.quote_imgbtm}
                          src={Quote2}
                          alt="Freeman Thomas"
                        />
                      </div>
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default ClientsSaySlider;
