// Packages
import { BrowserRouter } from 'react-router-dom';

// Configs
import config from './config';

// CSS Files
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/style.scss';

// Routes
//import AppRoutes from './AppRoutes';

import Routes from './routes';


const App = () => {
    return (
        <BrowserRouter basename={config.basename}>
            <Routes />
        </BrowserRouter>
    );
};

export default App;
