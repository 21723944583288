import AboutStatWorld from "../../components/AboutStatWorld/AboutStatWorld";
// import ManagementTeam from "../../components/AboutUs/ManagementTeam";
import BannerInner from "../../components/Banner/BannerInner";
import HomePageForm from "../../components/Home/HomePageForm";





const AboutCompany =  () => {
  const aboutBannerData = {
    heading: "About StatWorld",
  };
  return (
    <>
      <BannerInner bannerData={aboutBannerData} />
      <AboutStatWorld/>
      <HomePageForm />
    </>
  );
}

export default AboutCompany;
