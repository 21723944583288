import { Container } from 'react-bootstrap';
import assets from './../../assets/css/BannerInner.module.scss'
import { Link } from "react-router-dom";

const BannerInnr = ({bannerData}) => {
    return (
        <section className={`${assets.banner_innrs}`}>
            <Container className={assets.custom_container}>
                <h1>{bannerData.heading}</h1>
                <ul>
                    <li><Link to="/">Home</Link></li>
                    <li>{bannerData.heading}</li>
                </ul>
            </Container>
        </section>
    );
}
export default BannerInnr;
