import React from "react";
import {  Rachin, Rajat } from "../../assets/img";
import styles from "../../assets/css/about-management-team.module.scss";
import { Container } from "react-bootstrap";

const ManagementTeamDesign1 = () => {
  return (
    <>
      <section className={`pdtb pb-lg-0 ${styles.team}`} id="managementTeam">
        <Container>
        <div className={`sub-heading ${styles.heading_sec}`}>
                <h2 className="text-uppercase text-center">management <span>Team</span></h2>
                    <p>At the heart of our analytics-driven success story is a dynamic and visionary management
                       team that comprises of seasoned marketing professionals. The team having worked in various strategic
                       and entrepreneurial roles over their professional careers, brings a wealth of experience and expertise
                       to the table, steering our ship towards excellence in delivering customized analytics solutions to a
                       diverse global clientele. Together, they form the driving force behind our commitment to innovation,
                       data-driven insights, and unwavering client satisfaction. Get to know the individuals who lead us towards
                       a future defined by data, precision, and results.</p>
        </div>
         <div className={styles.team_member_card}>
         <div className="row align-items-center">
            <div className="col-lg-3 col-md-5 col-sm-6 col-10 mx-auto">
              <img
                src={Rachin}
                alt="Rachin"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-9">
              <div className={styles.content_sec}>
                <h3>Rachin Gupta</h3>
                <p>Rachin boasts a rich tapestry of professional experience spanning more than two decades, with expertise across diverse domains including management and strategy consulting, marketing analytics, market research, and logistics and supply chain management. Before founding StatWorld, Rachin held pivotal roles at esteemed organizations, where he made transformative contributions to their growth and success. His expertise extends far and wide, encompassing not only traditional business realms but also the cutting-edge fields of AI and ML. His leadership acumen shines through in his stewardship of sizable business units, and he possesses a wealth of experience collaborating closely with international clients and Fortune 500 companies on a multitude of market research, analytics, and consulting endeavors, many of which leverage the power of AI and ML to extract actionable insights and drive innovation. Rachin is a distinguished alumnus, holding an MBA degree in Marketing and Strategy from the prestigious Indian School of Business (ISB) in Hyderabad, coupled with a Bachelors in Technology from the globally renowned Indian Institute of Technology (IIT) in Delhi.</p>
              </div>
            </div>
          </div>
         </div>
         <div className={styles.team_member_card}>
         <div className="row align-items-center">
            <div className="col-lg-3 col-md-5 col-sm-6 col-10 mx-auto order-1 order-lg-2">
              <img
                src={Rajat}
                alt="rajat"
                className="img-fluid"
              />
            </div>
            <div className="col-lg-9 order-2 order-lg-1">
              <div className={styles.content_sec}>
                <h3>Rajat Goel</h3>
                <p>Rajat has an impressive track record, amassing over 17 years of expertise in the realms of market research, marketing analytics, logistics, and supply chain management. Prior to co-founding StatWorld, he honed his skills in a diverse array of professional settings, ranging from nimble start-ups to medium and large-scale enterprises. Rajat`s contributions to these organizations were nothing short of transformative, driving their growth and success. His expertise is underscored by a robust technical knowledge base, cultivated through a myriad of experiences. Rajat has fostered close collaborations with a wide array of Fortune 500 international clients, spanning across various industries. His proficiency extends across a multitude of domains, including but not limited to pricing research, market segmentation, conjoint analysis, brand strength assessment, market sizing, forecasting, ad effectiveness evaluation. Armed with expertise in AI and ML, he excels in harnessing the power of advanced technologies to drive transformative results. Rajat is a distinguished  alumnus, holding an MBA from the prestigious Institute of Management Technology (IMT) in Ghaziabad and a Bachelors in Technology from the renowned National Institute of Technology (NIT) in Jalandhar.</p>
              </div>
            </div>
          </div>
         </div>
        </Container>
      </section>
    </>
  );
};

export default ManagementTeamDesign1;
