import React from "react";
import styles from "../../assets/css/service-page.module.scss";

const ServiceDetailsList = ({ service }) => {
  return (
    <div className={`${styles.right_content_sec_inner}`}>
      {service.heading && <h2>{service.heading}</h2>}
      {service.des && <>{service.des}</>}
      {
        service.imgSec && <img src={service.imgSec.src} className="img-fluid" alt={service.imgSec.alt} />
      }
      {service.contentList && (
        <ul>
          {service.contentList.map((list, i) => (
            <li key={i}>
              {list.list}
              {list.listOfList && (
                <ul className={styles.inner_list}>
                  {list.listOfList.map((innerList, j) => (
                    <li key={j}>{innerList}</li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      )}
      {service.endLinkSec && service.endLinkSec}
    </div>
  );
};

export default ServiceDetailsList;
