export const HomePageDataMeta = {
  helmetSec: {
    title:
      "StatWorld ONE-STOP solution for all your Market Research Analytics needs",
    description:
      "StatWorld ONE-STOP solution for all your Market Research Analytics needs",
    keywords:
      "StatWorld ONE-STOP solution for all your Market Research Analytics needs",
  },
};
export const ContactPageDataMeta = {
  helmetSec: {
    title:
      "Conatact Us",
    description:
      "Conatact Us",
    keywords:
      "Conatact Us",
  },
};