import HmgBanner from "../../components/Home/HmgBanner";
import HmgAboutus from "../../components/Home/HmgAboutus";
import ServiceHmg from "../../components/Home/ServiceHmg";
import ClientImgHmg from "../../components/Home/ClientImgHmg";
import UnlockPower from "../../components/Home/UnlockPower";
import OurExpertise from "../../components/Home/OurExpertise";
import ClientsSaySlider from "../../components/Home/ClientsSaySlider";
import HomePageForm from "../../components/Home/HomePageForm";
import { HomePageDataMeta } from "../../components/jsonData/HomePageDataMeta";
import HeadMetaSec from "../../components/HeadMetaSec";

function Home() {
  return (
    <>
      <HeadMetaSec HeadSecData={HomePageDataMeta.helmetSec} />
      <HmgBanner />
      {/* <HmgAboutus /> */}
      <ServiceHmg />
      {/* <ClientImgHmg /> */}
      {/* <UnlockPower /> */}
      {/* <OurExpertise /> */}
      <ClientsSaySlider />
      <HomePageForm />
    </>
  );
}

export default Home;
