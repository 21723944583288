import { useParams } from "react-router-dom";
import { ServiceList } from "../../components/jsonData/ServiceData";
import { ServiceData } from "../../components/jsonData/ServiceData";
import ServiceDetail from "../../components/Services/ServiceDetail";
import BannerInner from "../../components/Banner/BannerInner";
import Home from "./Home";
import HomePageForm from "../../components/Home/HomePageForm";
import Cta from "../../components/Cta/Cta";

const ServicePage = () => {
  const params = useParams();
  const { name } = params || {};
  const linkData = ServiceList.find((item) => item.name === name);
  const contentData = ServiceData.find((item) => item.link === name);

  return (
    <>
      {contentData ? (
        <>
          <BannerInner bannerData={contentData.bannerSec} />
          <ServiceDetail
            ServiceList={ServiceList}
            linkData={linkData}
            contentData={contentData}
          />
          {/* <Cta/> */}
          <HomePageForm />
        </>
      ) : (
        <Home />
      )}
    </>
  );
};

export default ServicePage;
