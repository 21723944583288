import React, { useState, useRef } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Link } from "react-router-dom";
import { formContactus } from "../../assets/img";
import { BiSolidPhone } from "react-icons/bi";
import { IoIosMail, IoIosPaperPlane } from "react-icons/io";
import { FaLocationArrow } from "react-icons/fa";
import styles from "./../../assets/css/HomePageForm.module.scss";
import { Modal, Button, Row, Col, Spinner } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import {
  CustomInputComponent,
  CustomTextareaComponent,
} from "../formik/FieldComponents";
import { Formik, Form, Field } from "formik";

const HomePageForm = () => {
  const [show, setShow] = useState(false);

  const MySwal = withReactContent(Swal);
  const [loading, setLoading] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState();

  const API_PATH = "https://myleads.scrumdigital.in/api/leads";
  async function FormSubmit(event) {
    console.log(event);
    event.preventDefault();
    setLoading(true);
    let { fname, email, mobile, description } = event.target;
    let formData = {
      clientID: "AQQ4yOGJ1WFfdzU8",
      name: fname.value,
      email: email.value,
      mobile: phoneNumber,
      description: description.value,
      uuid: window.localStorage.getItem("scrum-visitor-trackingId"),
    };
    axios({
      method: "POST",
      url: `${API_PATH}`,
      headers: { "content-type": "application/json" },
      data: JSON.stringify(formData),
    })
      .then((result) => {
        setShow(false);
        MySwal.fire({
          title: <p>Success!!</p>,
          html: (
            <p>
              We have received your information and one of our representative
              will get in touch with you shortly to schedule an appointment.
            </p>
          ),
        });
        event.target.reset();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  }

  const onChange = () => { };
  return (
    <>
      <section className={`${styles.home_form} pdtb`} id="homepagecontact">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <form
                className={`${styles.home_form_left}`}
                onSubmit={FormSubmit}
              >
                <div className="sub-heading mb-0">
                  <h2 className="text-white">Let’s Connect</h2>
                  <p className="text-white">
                    Thank you for your interest in StatWorld Analytics. Please
                    provide us the requested information below and we will get
                    back to you at the earliest.
                  </p>
                </div>
                <div className={`${styles.frm_input_mb}`}>
                  <input
                    type="text"
                    className={`${styles.frm_cntrl} form-control`}
                    placeholder="Full Name *"
                    name="fname"
                    required
                  />
                </div>
                <div className={`${styles.frm_input_mb}`}>
                  <PhoneInput
                    placeholder="Phone number"
                    international
                    defaultCountry="IN"
                    value={phoneNumber}
                    onChange={(data) => setPhoneNumber(data)}
                    countryCallingCodeEditable={false}
                    rules={{ required: true }}
                    className={`${styles.frm_cntrl} form-control`}
                  />
                </div>

                <div className={`${styles.frm_input_mb}`}>
                  <input
                    type="email"
                    className={`${styles.frm_cntrl} form-control`}
                    name="email"
                    required
                    placeholder="Email Address *"
                  />
                </div>
                <div className={`${styles.frm_input_mb}`}>
                  <textarea
                    className={`${styles.frm_cntrl} form-control`}
                    rows="3"
                    required
                    name="description"
                    placeholder="Message*"
                  ></textarea>
                </div>
                <Button
                  variant="submit"
                  className="theme-btn"
                  type="submit"
                  disabled={loading}
                >
                  {loading && (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Submit
                </Button>
              </form>
            </div>
            <div className="col-lg-5">
              <div className={`${styles.bottom_form}`}>
                <div className={`${styles.contact_img}`}>
                  <img className="img-fluid" src={formContactus} />
                  <span>Contact US</span>
                </div>
                <div className={`sub-heading mb-0 ${styles.sub_heading}`}>
                  {/* <h2 className="mb-0">Let’s Connect</h2> */}
                  <p>You can email us your requirements at <Link to="mailto:info@statworldanalytics.com">info@statworldanalytics.com</Link> and our team will contact you within 24 hours.</p>
                  {/* <p className="mb-0">Thank you for your interest in StatWorld. Kindly provide us the following information so that we can contact you at the earliest. Alternatively, you can also email us about your requirement on info@statworldanalytics.com.</p> */}
                </div>
                <ul>
                  <li>
                    <i><FaLocationArrow /></i>
                    <p>102, Abbe Road<br />Little Falls, NJ, 07424<br />United States</p>
                    {/* <p>102 Abbe Road, Little Falls, NJ, 07424 United States</p> */}
                  </li>
                  <li>
                    <i><FaLocationArrow /></i>
                    <p>C-602, Sarve Satyam CGHS Ltd.<br />Plot No. 12, Sector 4<br />Dwarka, New Delhi, 110078<br />India</p>
                    {/* <p>C-602, Sarve Satyam CGHS Ltd, Plot No. 12, Sector – 4, Dwarka, New Delhi-110078 India</p> */}
                  </li>
                </ul>
                {/* <ul>
                  <li>
                    <i>
                      <BiSolidPhone />
                    </i>
                    <Link to="tel:+919865685678">+91 965 001 0709</Link>
                  </li>
                  <li>
                    <i>
                      <IoIosMail />
                    </i>
                    <Link to="mailto:info@statworldanalytics.com">
                      info@statworldanalytics.com
                    </Link>
                  </li>
                  <li>
                    <i>
                      <FaLocationArrow />
                    </i>
                    <p>
                      C-602, Sarve Satyam CGHS Ltd., Plot No: 12, Sector-4,
                      Dwarka – 110078(INDIA)
                    </p>
                  </li>
                  <li>
                    <i>
                      <FaLocationArrow />
                    </i>
                    <p>102 Abbe Road, Little Falls, NJ, 07424(USA)</p>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default HomePageForm;
