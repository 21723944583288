export const testimonialData = [
  {
    id: 1,
    pid:"#client1",
    companyName: "Brand Strategy Consulting Firm in the US",
    designation: "President of a Brand Strategy",
    desc: (
      <p>
        We have worked with StatWorld for almost 10 years. During that time they
        have become an integral part of our team. StatWorld supports us with
        survey design, programming, sample sourcing, analysis and presentation
        services. Their work is always exceptional...on time and on budget. They
        go beyond just delivery to providing essential insights that drive
        business results. Their work is invaluable and I would highly recommend
        them to any company looking to gain an intelligence advantage.
      </p>
    ),
  },
  {
    id: 2,
    pid:"#client2",
    companyName: "Pricing Strategy Firm in the US",
    designation: "CEO of a Pricing Strategy Firm",
    desc: (
      <p>
        We have now successfully worked together on a number of projects
        covering multiple industries, and I am very pleased with our
        collaboration. Below are the main factors I believe have made our
        collaborations successful.
      </p>
    ),
    list: [
      "You have taken the time to understand our requirements in some depth, and offered multiple potential solutions",
      "You have been diligent in explaining your methodologies and the implications of the results",
      "You allowed and facilitated us providing the domain specific expertise, while you provided the expertise on methodologies and tools for analytical projects and surveys",
      "When we have had suggestions for modifying variables or approaches, you have discussed those suggestions and adopted them where appropriate",
      "It has been easy to discuss the work streams along the way via Skype",
      "The workstreams are completed in the agreed timeframes and have been high quality",
    ],
  },
  {
    id: 3,
    pid:"#client3",
    companyName: "Strategy Consulting Firm in the EU",
    designation: "CEO of a Strategy Consulting",
    desc: (
      <>
        <p>
          I have been working with StatWorld for almost 10 years now and have
          always enjoyed working with them. Their subject matter expertise in
          Market Research and Advanced Analytics, especially in Discrete Choice
          Modeling is top rated. The guidance they provide in research
          conceptualization phase is praise worthy and have always been very
          helpful to me in deciding the most suitable research for my clients.
          They have a sound knowledge and strong technical expertise in using
          Sawtooth software for general surveys as well as conjoint surveys.
        </p>
        <p>
          StatWorld team has always been very responsive to emails and requests,
          and you get a personalized attention from Partners of the
          organization, something you don’t get to see most often. We have
          worked on a myriad of projects together, ranging from simple projects
          to very complex multi-country Conjoint projects, and their work
          quality is excellent, at parwith some of the best organizations I have
          worked with. Being one of my preferred vendors,I recommend StatWorld
          and hope they continue to add value to their clients.
        </p>
      </>
    ),
  },
  {
    id: 4,
    pid:"#client4",
    companyName: "Management Consulting Firm in the UK",
    designation: "Analytics Director",
    desc: (
      <p>
        I have worked and partnered with StatWorld on several projects over the
        last 8 years. StatWorld’s proficiency in survey programming, project
        management and quality of deliverables whether it is a standard or
        complex project is excellent. They are one of the best partners&#39; I
        have worked with. Besides their customer service extends past the life
        of the project. Rajat and Rachin are extremely responsive and customize
        the solution to fit the needs of each and every client. I would be happy
        to recommend StatWorld at any point of time.
      </p>
    ),
  },
  {
    id: 5,
    pid:"#client5",
    companyName: "Brand Management Firm in the US",
    designation: "Director of Analytics",
    desc: (
      <>
        <p>
          I am really impressed with the outstanding analytics services provided
          by StatWorld. From the moment we engaged with their team, their
          dedication to our project was excellent. Getting a profound
          understanding of our business objectives quickly, they suggested many
          things which helped us implement the research much more impactfully.
          Throughout the engagement, their attention to detail was exceptional,
          and their ability to distil complex data into actionable
          recommendations was instrumental in driving our strategic decisions,
          which really helped us see improvements in many areas.
        </p>
        <p>
          I have no hesitation in recommending StatWorld to anyone seeking
          top-notch analytics services. Their expertise and professionalism have
          made a significant impact on our success, and we look forward to
          continuing our partnership in the future.
        </p>
      </>
    ),
  },
];
