import { Link,NavLink, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import {BsChevronDown} from 'react-icons/bs';
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { LogoMain } from "../../assets/img";
import NavDropdown from "react-bootstrap/NavDropdown";

const Header = () => {
  const pathName =useLocation();
  const [showDrop, setShowDrop] = useState(false);
  const [scrollHeader, setScrollHeader] = useState(false);
  useEffect(() => {
    let elementId = document.getElementById("header");
   if(window.innerWidth>991){
    document.addEventListener("scroll", () => {
      if (window.scrollY > 500) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
   }else{
    document.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        elementId.classList.add("is-sticky");
      } else {
        elementId.classList.remove("is-sticky");
      }
    });
   }
  });
  const handleDropDown = ()=>{
    setShowDrop(!showDrop)
  }

  useEffect(()=>{
    setShowDrop(false)
    setScrollHeader(false);
  }, [pathName])
  console.log(window.width)

  return (
    <>
      <header className={`th-header`} id="header">
        <Navbar expand="lg">
          <Container>
            <Link to="/" className="navbar-brand">
              <img src={LogoMain} alt="" />
            </Link>
            <Navbar.Toggle aria-controls="basic-navbar-nav"  onClick={() => setScrollHeader(!scrollHeader)} />
            <Navbar.Collapse id="basic-navbar-nav" className={!scrollHeader ? "" : "shows"}>
              <Nav className="ms-auto align-items-center">
                <NavLink to="/" className="nav-link">
                  Home
                </NavLink>
                <NavDropdown
                  show={showDrop}
                  onToggle={handleDropDown}
                  title=<span>Services <BsChevronDown/></span>
                  id="basic-nav-dropdown">
                  <ul className="drp-dwn-ul">
                    <li className="drp-dwn-li">
                      <NavLink to="/market-research" className="drp-dwn-link">
                        Market Research
                      </NavLink>
                      {/* <ul class="drp-dwn-ul-innr">
                        <li>
                          <Link to="/market-research/#research" className="">
                            Research Design &amp; Questionnaire Development
                          </Link>
                        </li>
                        <li>
                          <Link to="/market-research/#survey" className="">
                            Survey Programming &amp; Hosting
                          </Link>
                        </li>
                        <li>
                          <Link to="/market-research/#data" className="">
                            Data Collection &amp; Field Management
                          </Link>
                        </li>
                        <li>
                          <Link to="/market-research/#analysis" className="">
                            Analysis &amp; Reporting
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="drp-dwn-li">
                      <NavLink to="/advanced-analytics" className="drp-dwn-link">
                        Advanced Analytics
                      </NavLink>
                      {/* <ul class="drp-dwn-ul-innr">
                        <li>
                          <Link to="/advanced-analytics/#discrete" className="">
                            Discrete Choice Modeling
                          </Link>
                        </li>
                        <li>
                          <Link to="/advanced-analytics/#market" className="">
                            Market Segmentation
                          </Link>
                        </li>
                        <li>
                          <Link to="/advanced-analytics/#pricing" className="">
                            Pricing Research
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/advanced-analytics/#marketing"
                            className=""
                          >
                            Marketing &#038; Sales Effectiveness
                          </Link>
                        </li>
                        <li>
                          <Link to="/advanced-analytics/#crm">
                            CRM Analytics
                          </Link>
                        </li>
                        <li>
                          <Link to="/advanced-analytics/#marketassessment">
                            Market Assessment &#038; Commercialization
                          </Link>
                        </li>
                        <li>
                          <Link to="/advanced-analytics/#financial">
                            Financial Modeling
                          </Link>
                        </li>
                        <li>
                          <Link to="/advanced-analytics/#predictive">
                            Predictive Modeling
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="drp-dwn-li">
                      <NavLink to="/social-media-analytics" className="drp-dwn-link">
                      Social Media Analytics
                      </NavLink>
                      {/* <ul class="drp-dwn-ul-innr">
                        <li>
                          <Link to="/other-research/#social" className="">
                            Social Media Analytics
                          </Link>
                        </li>
                        <li>
                          <Link to="/other-research/#industry" className="">
                            Industry Research
                          </Link>
                        </li>
                        <li>
                          <Link to="/other-research/#company" className="">
                            Company/Competitive Intelligence
                          </Link>
                        </li>
                        <li>
                          <Link to="/other-research/#automation" className="">
                            Automation
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="drp-dwn-li">
                      <NavLink
                        to="/bi-data-visualization"
                        className="drp-dwn-link"
                      >
                        BI &#038; Data Visualization
                      </NavLink>
                      {/* <ul className="drp-dwn-ul-innr">
                        <li>
                          <Link
                            to="/bi-data-visualization/#interactive"
                            className=""
                          >
                            Interactive Dashboards
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/bi-data-visualization/#Periodic"
                            className=""
                          >
                            Periodic Performance Reporting
                          </Link>
                        </li>
                      </ul> */}
                    </li>
                    <li className="drp-dwn-li">
                      <NavLink
                        to="/etl-implementation"
                        className="drp-dwn-link"
                      >
                       ETL Implementation
                      </NavLink>
                    </li>
                  </ul>
                </NavDropdown>
                <NavLink to="about-us" className="nav-link">
                  About Us
                </NavLink>
                <NavLink to="/testimonials" className="nav-link">
                Testimonials
                </NavLink>
                {/* <Link to="/testimonials" className="nav-link">Testimonials</Link> */}
                {/* <Link to="about-us" className="nav-link">About Us</Link> */}
                {/* <Link href="#link" className="nav-link">Media Coverage</Link> */}
                {/* <Link href="#link" className="nav-link">Careers</Link>
                <Link href="#link" className="nav-link">Media Coverage</Link> */}
              </Nav>
              <Nav className="align-items-center">
                <Link to="/contact-us" className="theme-btn">
                  Contact Us
                </Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>
    </>
  );
};

export default Header;
