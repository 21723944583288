import React, { useState } from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import {FaAngleRight, FaTimes} from 'react-icons/fa'
import styles from "../../assets/css/service-page.module.scss";
import ServiceDetailsList from "./ServiceDetailsList";

const ServiceDetail = ({ ServiceList, contentData, linkData }) => {
 const [showSideBar, setShowSideBar] = useState(false);

 const handleShowSideBar = ()=>{
  setShowSideBar(!showSideBar)
 }

  return (
    <>
      <section className={`pdtb pb-lg-0 ${styles.service_sec}`} >
        <Container>
          <ul className="d-flex flex-wrap">
            <li className={styles.left_content}>
              {
                <div className={styles.right_content_sec}>
                  {contentData.desc && <p>{contentData.desc}</p>}
                  {contentData.content && contentData.content.map(
                    (service, i) =>
                      <ServiceDetailsList service={service} key={i} />
                  )}
                  {
                    contentData.imgSec && <img src={contentData.imgSec.src} className="img-fluid" alt={contentData.imgSec.alt}/>
                  }
                  {
                    contentData.imgSec1 && <img src={contentData.imgSec1.src} className="img-fluid mt-5" alt={contentData.imgSec1.alt}/>
                  }
                </div>
              }
            </li>
            <li className={`${styles.right_move_link} ${showSideBar ? styles.show:styles.hide}`}>
              <div
                className={` ${
                  styles.left_link_sec
                }`}
              >
               <button className={styles.btn} onClick={handleShowSideBar}>{showSideBar ? <FaTimes/>  : <FaAngleRight/>}</button>
                <div className={styles.left_link_sec_header}>
                  <h3>{linkData.title}</h3>
                </div>
                <ul className={styles.main_link}>
                  {ServiceList.map((list, i) => (
                    <li key={i}>
                      {linkData.name === list.name ? (
                        <Link to={`/${list.name}/`} className={styles.active}>
                          {list.title}
                        </Link>
                      ) : (
                        <Link to={`/${list.name}/`}>{list.title}</Link>
                      )}
                      {list.innerList && linkData.name === list.name ? (
                        <ul className={styles.inner_link}>
                          {list.innerList.map((e, index) => (
                            <li key={index}>
                                {e.name}
                            </li>
                          ))}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </li>
          </ul>
        </Container>
      </section>
    </>
  );
};

export default ServiceDetail;
