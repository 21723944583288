import React from "react";
import styles from "../../assets/css/about-statWorld.module.scss";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import { AboutStatWorldData } from "./AboutStatWorldData";
// import { analyticsEcosystem, analyticsMarketResearch, brandMonitor } from "../../assets/img";

const AboutStatWorld = () => {
  return (
    <section className={` ${styles.about_company}`}>
      {AboutStatWorldData.map((item, i) => (
        <div className={styles.about_company_main} id={item.pid} key={i}>
          <Container>
            <div className="row align-items-center justify-content-between">
              <div
                className={` col-xl-5 col-lg-5 col-md-7 col-sm-7 text-center mx-auto ${
                  item.orderImg && item.orderImg
                }`}
              >
                <img src={item.imgSrc} className="img-fluid" alt={item.alt} />
              </div>
              <div
                className={`col-lg-7 ${item.orderContent && item.orderContent}`}
              >
                <div className={styles.about_company_card}>
                  <h2>{item.heading}</h2>
                  <p>{item.desc}</p>

                  {item.list && (
                    <ul>
                      {item.list.map((list, i) => (
                        <li key={i}>{list}</li>
                      ))}
                    </ul>
                  )}
                </div>
                <div className="mt-5">
                  <Link to={item.linkSec.href} className="theme-btn">
                    {item.linkSec.name}
                  </Link>
                </div>
              </div>
            </div>
          </Container>
        </div>
      ))}
      {/* <div className={styles.about_company_main} id="customizedAnalytics">
             <Container>
             <div className="row align-items-center justify-content-between">
                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-7 mx-auto">
                   <img src={analyticsMarketResearch} className="img-fluid" alt="customizedAnalytics" />
                </div>
                <div className="col-lg-7">
                  <div className={styles.about_company_card}>
                      <h2>Customized Analytics and <span>Market Research Solutions!</span></h2>
                      <p>
                        Our bespoke market research and analytics solutions are
                        uniquely crafted to meet your specific business needs,
                        empowering you with the strategic intelligence necessary
                        to outperform the competition. At StatWorld, we are
                        dedicated to provide our global clientele with a
                        competitive edge through our personalized analytics and
                        comprehensive market research offerings. Our expertise
                        lies in harnessing advanced statistical methods, AI
                        capabilities, and state-of-the-art technology to shape
                        precise insights that perfectly match your business&#39;s
                        distinctive requirements. We offer solutions that empower
                        you to navigate complexities confidently, ensuring that
                        every strategic decision you make is backed by data-driven
                        analysis and insights, that fuel your global success.
                      </p>
                      <div className="mt-5">
                        <Link to="/market-research" className="theme-btn">service offerings.</Link>
                      </div>
                  </div>
                </div>
              </div>
             </Container>
            </div>
            <div className={styles.about_company_main} id="ecosystem">
              <Container>
              <div className="row align-items-center justify-content-between">
                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-7 mx-auto  order-2">
                <img src={analyticsEcosystem} className="img-fluid" alt="INTEGRATED ANALYTICS ECOSYSTEM!" />
                </div>
                <div className="col-lg-7 order-1">
                  <div className={styles.about_company_card}>
                      <h2>INTEGRATED ANALYTICS ECOSYSTEM!</h2>
                      <p>
                        STATWORLD is your ONE-STOP destination for Customized
                        Market Research, Advanced Analytics, Social Media
                        Analytics, ETL Implementation, Data Visualization, and
                        related services. Our integrated analytics ecosystem
                        empowers us to fulfil all client requirements in-house,
                        ensuring a seamless and comprehensive service experience.
                      </p>

                    <ul>
                      <li>Customized Market Research</li>
                      <li>Advanced Analytics Solutions</li>
                      <li>Social Media Analytics</li>
                      <li>Effortless ETL Implementation</li>
                      <li>Data Visualization &amp; Dynamic Dashboards</li>
                    </ul>
                    </div>
                    <div className="mt-5">
                        <Link to="/market-research" className="theme-btn">service offerings.</Link>
                      </div>
                  </div>
                </div>
              </Container>
              </div>
            <div className={styles.about_company_main} id="brandStrength">
            <Container>
              <div className="row align-items-center justify-content-between">
                <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-7 mx-auto">
                <img src={brandMonitor} className="img-fluid" alt="brandMonitor" />
                </div>
                <div className="col-lg-7">
                  <div className={styles.about_company_card}>
                      <h2>StatWorld’s Brand Strength Monitor (BSM)!</h2>
                      <p>
                        StatWorld&#39;s Brand Strength Monitor (BSM) is a
                        transformative and comprehensive brand strength evaluation
                        instrument, meticulously designed for the systematic
                        assessment of your brand&#39;s health and effectiveness in
                        the market vis-à-vis competition. Operating across six key
                        dimensions, the BSM has the power to catapult your brand
                        to new heights and magnify its impact. Throughout our
                        years of experience, we have harnessed this research
                        instrument with remarkable success, bolstering the
                        performance and marketing ROI of numerous multinational
                        giants. The BSM delivers precise, actionable insights,
                        illuminating the path to strategic clarity and the
                        development of a well-defined roadmap for enhancing brand
                        performance and driving sustainable brand growth.&quot;
                      </p>
                      <div className="mt-5">
                        <Link to="/market-research" className="theme-btn">service offerings.</Link>
                      </div>
                    </div>
                  </div>
                </div>
                </Container>
              </div> */}
    </section>
  );
};

export default AboutStatWorld;
